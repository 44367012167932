<template>
    <div class="wrapper">
        <div class="position-relative w-100 vh-50 mb-5">
            <div class="img-fixed h-100 w-100">
                <img :src="ImgPortada" alt="Encuentra una mesa de regalo" class="img-background h-100 w-100">
            </div>
            <div class="position-absolute top-0 bottom-0 start-0 end-0">
                <div class="d-flex align-items-center justify-content-center h-100">
                    <h1 class="text-white">Carrito de compras.</h1>
                </div>
            </div>
        </div>
        <div class="form-row justify-content-center">
            <div class="col-lg-12" v-if="boda.CODIGO_BODA">
                <h1 class="playfairDisplay display-1 text-center">{{nombre1}} & {{nombre2}}</h1>
            </div>
            <div class="col-lg-8">
                <div v-if="items.length>0">
                    <div class="table-responsive" v-if="items.length>0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Producto</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in items" :key="index">
                                    <th>
                                        <button type="button" class="btn btn-link p-1 text-danger" v-on:click="deleteItem(item)" title="Eliminar">
                                            <font-awesome-icon :icon="faTrash" />
                                        </button>
                                    </th>
                                    <th>
                                        <span v-if="item.producto.adjuntos && item.producto.adjuntos.length>0">
                                            <img :src="item.producto.adjuntos[0].ARCHIVO" :alt="item.producto.NOMBRE_PRODUCTO" height="30" width="30">
                                        </span>
                                        
                                        {{item.producto.NOMBRE_PRODUCTO}}
                                        <div v-if="errors[index]">
                                            <span class="text-danger">
                                                {{errors[index][0]}}
                                            </span>
                                        </div>
                                    </th>
                                    <td>
                                        {{$filters.currency(item.producto.PRECIO)}}
                                    </td>
                                    <td>
                                        <input type="number" v-model="item.CANTIDAD_ITEMS" min="0" :max="item.CANTIDAD" class="form-control" placeholder="Cantidad">
                                    </td>
                                    <td>
                                        {{$filters.currency(item.producto.PRECIO*item.CANTIDAD_ITEMS)}}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-end">
                                        Total
                                    </td>
                                    <th>
                                        {{$filters.currency(total)}}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-end">
                        <button type="button" class="btn btn-secondary" v-on:click="pago()" :disabled="loadingPago">
                            <BtnLoading v-if="loadingPago" text="Procesando el pago..."/>
                            <span v-else>
                                Proceder al pago <font-awesome-icon :icon="faGifts" />
                            </span>
                        </button>
                    </div>
                </div>
                <div class="text-center" v-else>
                    <h1>Tu carrito está vacio.</h1>
                    <router-link :to="{name: 'mesas.index'}" class="btn btn-secondary">
                        Encuentra una mesa
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ImgPortada from '@/assets/img/backgrounds/carrito.jpg'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGifts, faTrash, faFileImage } from '@fortawesome/free-solid-svg-icons'
import BtnLoading from '@/components/BtnLoading.vue'
export default {
    components: {
        FontAwesomeIcon,
        BtnLoading
    },
    data(){
        return {
            ImgPortada,
            faGifts,
            faTrash,
            faFileImage,
            loadingPago: false,
            loadingBoda: false,
            boda: {}
        }
    },

    mounted(){
        this.getBoda()
    },

    methods: {
        deleteItem(val){
            this.$store.commit('deleteItem', val)
            this.$toast.success('El producto ha sido eliminado.')
        },

        getBoda(){
            if(!this.codigoBoda) {return}
            this.boda = {}
            this.loadingBoda = true
            this.$store.dispatch('getBodas', {
                CODIGO_BODA: this.codigoBoda,
                pagination: 0
            }).then((res) => {
                if (res.data) {
                    if (res.data.length>0) {
                        this.boda = res.data[0] || {}
                    }
                }

                return res
            }).catch(err => {
                this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingBoda = false
            })
        },

        pago(){
            this.loadingPago = true
            this.$store.dispatch('mesasInvitadosStore', {
                items: this.items
            }).then((res) => {
                this.$toast.info(`Pago realizado con éxito`)
                this.$router.push({name: 'mesas.index'})
                this.$store.commit('deleteAllItems')
                return res
            }).catch(err => {
                if(err.response){
                    if(typeof err.response.data === 'string'){
                        this.$toast.error(err.response.data)
                    }else{
                        this.$toast.error('Error al realizar cobro')
                    }
                }
            }).then(() => {
                this.loadingPago = false
            })
        }
    },
    computed: {
        errors() {
            return this.$store.getters.errors || {}
        },
        items() {
            return this.$store.getters.items || []
        },
        codigoBoda(){
            if (this.items[0]) {
                return this.items[0].CODIGO_BODA || -1
            }
            return -1
        },

        nombre1(){
            var data = this.boda.NOMBRE_PAREJA_1 || ''
            return data.split(' ')[0]
        },
        nombre2(){
            var data = this.boda.NOMBRE_PAREJA_2 || ''
            return data.split(' ')[0]
        },

        total(){
            var tot = 0
            for (var i = this.items.length - 1; i >= 0; i--) {
                var producto = this.items[i].producto || {}
                var precio = producto.PRECIO || 0
                var cantidad = this.items[i].CANTIDAD_ITEMS || 0
                tot = tot + parseFloat(precio * cantidad)
            }

            return tot

            /*return this.items.reduce(function(a, b){ 
                var monto = 0
                console.log(a)
                if(typeof a === 'object'){
                    monto = (parseFloat(a.CANTIDAD_ITEMS || 0) * parseFloat(a.producto ? a.producto.PRECIO : 0))
                }else{
                    monto = a
                }
                var precio2 = b.producto ? b.producto.PRECIO : 0

                return parseFloat(monto || 0)
                    + 
                (parseFloat(b.CANTIDAD_ITEMS || 0) * parseFloat(precio2))
            })*/
        }
    },
    watch: {
        items: {
            handler(val){
                this.$store.commit('addItems', val)
            },
            deep: true
        }
    }
}
</script>